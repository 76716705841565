<template>
  <div class="UserAdminMenuPublic">
    <LargeButton :label="$locale['login']" icon="user" :desc="$locale['login_benefits']" @click="goToThisPath('/login')" />
    <LargeButton :label="$locale['signup_label']" icon="user-pen" __pen :desc="$locale['signup_benefits']" @click="goToThisPath('/signup')" />
    <LargeButton
      :label="$locale['help_label']"
      icon="help"
      :desc="$locale['help_support_dec']"
      @click="modal.close(() => Go.sleep(300).then(openSupportView))"
    />
    <Spacer :num="$isMobile ? 2 : 1" />
  </div>
</template>

<script>
export default {
  props: ["modal"],
  methods: {
    goToThisPath: function(path) {
      this.modal.close(() => {
        this.$router.push(path);
      });
    },
  },
};
</script>

<style lang="scss">
.UserAdminMenuPublic {
  .LargeButtonLabel {
    font-family: $third_font_bold;
  }

  .LargeButton[__pen] .LargeButtonIcon {
    font-size: 120%;
  }
}
</style>
